import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import CryptoJS from 'crypto-js';
import { Authorization, ReleaseForms } from '@/constants/general';

interface Company {
    companyId: string | null;
    companyName: string | null;
    companyLogo: string | null;
    groupId: string | null;
    isSuperAdmin: boolean | null;
    isAdmin: boolean | null;
    testUser: boolean | null;
    auth: Authorization[] | null;
    forms: ReleaseForms[] | null;
}

interface SessionType {
    company: string | undefined;
    setSession: (session: any) => void;
    getSession: () => Company | undefined;
    clearSession: () => void;
}

const SECRET_KEY = process.env.NEXT_PUBLIC_LOCAL_STORAGE_KEY;

const encrypt = (data: any) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY!).toString();
};

const decrypt = (data: any) => {
    const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY!);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const useAppStore = create<SessionType>()(
    persist(
        (set, get) => ({
            company: undefined,
            setSession: (company: Company) => {
                // console.log(company);
                const encryptedCompany = encrypt(company);
                set({ company: encryptedCompany });
            },
            getSession: () => {
                const state = get();
                if (!state.company) {
                    return undefined;
                }
                const decryptedCompany = decrypt(state.company);
                return decryptedCompany;
            },
            clearSession: () => {
                // set(() => initialState);
                sessionStorage.removeItem('secure-storage');
                // sessionStorage.clear(); // or localStorage.clear();
            },
        }),
        {
            name: 'secure-storage',
            storage: createJSONStorage(() => sessionStorage),
        },
    ),
);
