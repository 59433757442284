import { S3ClientConfig } from '@aws-sdk/client-s3';
import { GroupSettingsModel, TextWrapSettingsModel } from '@syncfusion/ej2-react-grids';

export const cdate = () => {
    const ISToffSet = 330;
    // IST is 5:30; i.e. 60*5+30 = 330 in minutes
    const offset = ISToffSet * 60 * 1000;
    return new Date(Date.now() + offset);
};

export const COOKIE_NAME = 'OurSiteJWT';
export const MAX_AGE = 60 * 60 * 24 * 30; // days;
export const COMPANY_LOGO_BLANK = '/assets/images/Company_Blank31.png';
export const BKGROUND_IMAGE = '/assets/images/Landing1.jpg';
export const PDF_BLANK = '/assets/images/pdf_icon.png';
export const XLSX_BLANK = '/assets/images/xlsx_icon.png';
export const DOCX_BLANK = '/assets/images/docx_icon.webp';
export const GENERAL_BLANK = '/assets/images/general_icon.png';
export const MAN_AVATAR = '/assets/images/Man_Avatar.png';
export const EMPLOYEE_AVATAR = '/assets/images/EMPLOYEE_AVATAR5.svg';
export const ITEM_GROUP_AVATAR = '/assets/images/ITEM_GROUP_AVATAR.png';
export const ITEM_AVATAR = '/assets/images/ITEM_AVATAR3.png';
export const dealerForms = [3, 9, 13, 15];
export const dealerItemGroups = ['FILTERS', 'LUBRICANTS'];

export const change2CamelCase = (input: any) => {
    return input
        .split(/\r?\n/) // Split on newline characters (\r is optional for Windows-style line endings)
        .map((line: string) => {
            // Capitalize the first character of each word
            return line
                .split(/[ \t]+/) // Split each line on spaces and tabs
                .map((word: string, index: number, array: string[]) => {
                    // Capitalize the first character of each word
                    const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);

                    // If it's not the last word, add a space; otherwise, don't add anything
                    return index < array.length - 1 ? capitalizedWord + ' ' : capitalizedWord;
                })
                .join(''); // Join words without spaces
        })
        .join('\n'); // Join lines with newline characters
};

export const setGeneralFocus = (ctl: any) => {
    setTimeout(() => {
        if (ctl.current && typeof ctl.current.focus === 'function') {
            ctl.current.focus();
            if ('select' in ctl.current) {
                ctl.current.select();
            }
        }
    }, 200);
};

export const computeSHA256 = async (file: File) => {
    const buffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
};

export const toolbarOptions: any = [
    { text: 'Taskbar', tooltipText: 'Show Taskbar', prefixIcon: 'e-justify', id: 'taskbar', cssClass: 'show-taskmenu-on-small-screen' },
    'Print',
    'CsvExport',
    'PdfExport',
    'ExcelExport',
    'Search',
    'ColumnChooser',
];

export const groupOptions: GroupSettingsModel = { showGroupedColumn: true };
export const wrapSettings: TextWrapSettingsModel = { wrapMode: 'Content' }; // Header

export const searchOptions = {
    // fields: ['CustomerID'],
    ignoreCase: true,
    // key: 'Ha',
    operator: 'contains',
};

export const s3ClientConfig: S3ClientConfig = {
    region: process.env.NEXT_PUBLIC_AWS_BUCKET_REGION!,
    credentials: {
        accessKeyId: process.env.NEXT_PUBLIC_AWS_ACCESS_KEY!,
        secretAccessKey: process.env.NEXT_PUBLIC_AWS_SECRET_ACCESS_KEY!,
    },
};

export const rowsb4scroll = 15;

export const sfcombotemplate = (nodataId: string, btnId: string): string => {
    return `<div id="${nodataId}"> No matched item, do you want to add it as new item in list?</div> <button id="${btnId}" class="e-control e-btn">Add New Item</button>`;
};

export type ComboBoxItem = {
    id: string;
    name: string;
};

export const handleInputChange = (e: any, setFieldValue: any, fieldName: string, casetype: string, maxLength: number = 0, fieldRef: any) => {
    if (!e.target || e.target.value === undefined || e.target.value === null) {
        setFieldValue(fieldName, '', true);
        return; // Exit early if e.target or e.target.value is not valid
    }

    let value = e.target.value || '';

    // console.log('zz', value, casetype, fieldName, fieldRef);

    if (typeof value !== 'string') {
        value = String(value);
    }

    // Restrict the input length
    if (maxLength > 0 && value.length > maxLength) {
        value = value.substring(0, maxLength);
        e.target.value = value; // Manually set the target value to restrict further typing
    }

    if (casetype.toLowerCase() === 'upper') {
        value = e.target.value.toUpperCase();
    } else if (casetype.toLowerCase() === 'lower') {
        value = e.target.value.toLowerCase();
    } else if (casetype.toLowerCase() === 'camel') {
        value = change2CamelCase(value);
    } else if (casetype.toLowerCase() === 'number') {
        value = value.replace(/[^0-9]/g, ''); // Allow only digits
    } else if (casetype.toLowerCase() === 'decimal') {
        value = value.replace(/[^0-9.]/g, ''); // Allow only digits and decimal points
        // Ensure only one decimal point is allowed
        const parts = value.split('.');
        if (parts.length > 2) {
            value = parts[0] + '.' + parts.slice(1).join(''); // Remove any additional dots
        }
    }

    // Update the field value
    setFieldValue(fieldName, value, true);

    // Update fieldRef if not null
    if (fieldRef !== null) {
        fieldRef.current.text = value;
    }

    return value;
};

// Not In Use Now
export const handleVehiclePaste = (e: any, setFieldValue: any) => {
    const pasteData = e.clipboardData.getData('text').toUpperCase();
    if (pasteData.length > 10) {
        e.preventDefault();
        e.target.value = pasteData.substring(0, 10);
        setFieldValue('vehicle_no', pasteData.substring(0, 10), true);
    }
};

export type ModalState = 'View' | 'Edit' | 'Add' | '';

export const parseDateWithoutTime = (dateString: any) => {
    const date = new Date(dateString);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const getMimeType = (extension: string): string => {
    const mimeTypes: { [key: string]: string } = {
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        gif: 'image/gif',
        mp4: 'video/mp4',
        avi: 'video/x-msvideo',
        doc: 'application/msword',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        xls: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        pdf: 'application/pdf',
        ppt: 'application/vnd.ms-powerpoint',
        pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        txt: 'text/plain',
        csv: 'text/csv',
        // Add more extensions and their corresponding mime types as needed
    };

    return mimeTypes[extension.toLowerCase()] || 'application/octet-stream';
};

export const getFileTypeIcon = (fileUrl: string | null, fileName: string | null) => {
    if (fileName) {
        const extension = fileName.split('.').pop()?.toLowerCase();
        switch (extension) {
            case 'pdf':
                return PDF_BLANK; // Replace with actual path to PDF icon
            case 'doc':
            case 'docx':
                return DOCX_BLANK; // Replace with actual path to DOCX icon
            case 'xls':
            case 'xlsx':
                return XLSX_BLANK; // Replace with actual path to XLSX icon
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'webp':
            case 'gif':
                return fileUrl; // It's an image file
            default:
                return GENERAL_BLANK; // Replace with actual path to default file icon
        }
    } else {
        return null;
    }
};

// Function to open the image URL in a new tab
export const openImageInNewTab = (url: string) => {
    const fileType = url.split('.').pop();

    if (fileType === 'pdf') {
        //
    } else {
        const newWindow = window.open(url, '_blank');
        if (newWindow) {
            newWindow.focus();
        }
    }
};

export interface AuthorizationType {
    add: boolean | null;
    edit: boolean | null;
    delete: boolean | null;
    view: boolean | null;
    viewatt: boolean | null;
}

export interface Authorization {
    id: number;
    formId: number;
    name: string;
    group: string;
    subgroup: string;
    type: AuthorizationType;
}

export interface ReleaseForms {
    id: number;
    name: string;
    group: string | null;
    subgroup: string | null;
    isactive: boolean;
    isassignable: boolean;
    isreleased: boolean;
}

export const hasPagePermission = (
    authorization: Authorization[],
    isSuperAdmin: boolean,
    isAdmin: boolean,
    formId: number,
    testUser: boolean,
    forms: ReleaseForms[] | null,
    permissionType?: keyof AuthorizationType,
): AuthorizationType => {
    if (!forms || !forms.some((form) => form.id === formId)) {
        return {
            add: false,
            edit: false,
            delete: false,
            view: false,
            viewatt: false,
        };
    }
    if (testUser === true && (isSuperAdmin || isAdmin))
        return {
            add: true,
            edit: true,
            delete: true,
            view: true,
            viewatt: true,
        };

    // If testUser is false and the user is either a super admin or an admin
    if (!testUser && forms && (isSuperAdmin || isAdmin)) {
        // console.log(forms, testUser);
        const matchingForm = forms.find((f) => f.id === formId && f.isactive && f.isassignable && f.isreleased);
        if (matchingForm) {
            return {
                add: true,
                edit: true,
                delete: true,
                view: true,
                viewatt: true,
            };
        } else {
            return {
                add: false,
                edit: false,
                delete: false,
                view: false,
                viewatt: false,
            };
        }
    }

    if (authorization.length === 0)
        return {
            add: false,
            edit: false,
            delete: false,
            view: false,
            viewatt: false,
        };

    const formAuth = authorization.find((auth) => auth.formId === formId);
    if (!formAuth)
        return {
            add: false,
            edit: false,
            delete: false,
            view: false,
            viewatt: false,
        };

    // console.log(formAuth, permissionType);

    const permissions = { ...formAuth.type };

    if (permissionType) {
        return {
            ...permissions,
            [permissionType]: permissions[permissionType] || false,
        };
    }

    return permissions;
};

export const hasGroupPermission = (authorization: Authorization[], isSuperAdmin: boolean, isAdmin: boolean, groupName: string, testUser: boolean, forms: ReleaseForms[]) => {
    if (!forms.some((form) => form.group === groupName)) {
        return false;
    }
    if (testUser === true && (isSuperAdmin || isAdmin)) return true;

    // If testUser is false and the user is either a super admin or an admin
    if (!testUser && (isSuperAdmin || isAdmin)) {
        // console.log(forms, testUser);
        // Check if any form in the forms array matches the groupName and is released
        const matchingForm = forms.find((f) => f.group === groupName && f.isactive && f.isassignable && f.isreleased);
        if (matchingForm) {
            return true;
        } else {
            return false;
        }
    }

    if (authorization.length === 0) return false;

    const formAuth = authorization.find((auth) => auth.group === groupName && auth.type.view === true);
    if (!formAuth) return false;

    // console.log(formAuth, isSuperAdmin, isAdmin, groupName);
    return true;
};

export const hasSubGroupPermission = (authorization: Authorization[], isSuperAdmin: boolean, isAdmin: boolean, groupName: string, subGroupName: string, testUser: boolean, forms: ReleaseForms[]) => {
    // console.log(authorization);
    if (testUser === true && (isSuperAdmin || isAdmin)) return true;

    // If testUser is false and the user is either a super admin or an admin
    if (!testUser && (isSuperAdmin || isAdmin)) {
        // console.log(forms, testUser);
        // Check if any form in the forms array matches the groupName and is released
        const matchingForm = forms.find((f) => f.group === groupName && f.subgroup === subGroupName && f.isactive && f.isassignable && f.isreleased);
        if (matchingForm) {
            return true;
        } else {
            return false;
        }
    }

    if (authorization.length === 0) return false;

    const formAuth = authorization.find((auth) => auth.group === groupName && auth.subgroup === subGroupName && auth.type.view === true);
    if (!formAuth) return false;

    // console.log(formAuth, isSuperAdmin, isAdmin, subGroupName);
    return true;
};

export const employeeTypes = ['Driver', 'Helper', 'Mechanic', 'Office Staff', 'Manager'];

export const statesLists = [
    // Indian States
    { id: 1, name: 'Andhra Pradesh' },
    { id: 2, name: 'Arunachal Pradesh' },
    { id: 3, name: 'Assam' },
    { id: 4, name: 'Bihar' },
    { id: 5, name: 'Chhattisgarh' },
    { id: 6, name: 'Goa' },
    { id: 7, name: 'Gujarat' },
    { id: 8, name: 'Haryana' },
    { id: 9, name: 'Himachal Pradesh' },
    { id: 10, name: 'Jharkhand' },
    { id: 11, name: 'Karnataka' },
    { id: 12, name: 'Kerala' },
    { id: 13, name: 'Madhya Pradesh' },
    { id: 14, name: 'Maharashtra' },
    { id: 15, name: 'Manipur' },
    { id: 16, name: 'Meghalaya' },
    { id: 17, name: 'Mizoram' },
    { id: 18, name: 'Nagaland' },
    { id: 19, name: 'Odisha' },
    { id: 20, name: 'Punjab' },
    { id: 21, name: 'Rajasthan' },
    { id: 22, name: 'Sikkim' },
    { id: 23, name: 'Tamil Nadu' },
    { id: 24, name: 'Telangana' },
    { id: 25, name: 'Tripura' },
    { id: 26, name: 'Uttar Pradesh' },
    { id: 27, name: 'Uttarakhand' },
    { id: 28, name: 'West Bengal' },
    // Union Territories
    { id: 29, name: 'Andaman and Nicobar Islands' },
    { id: 30, name: 'Chandigarh' },
    { id: 31, name: 'Dadra and Nagar Haveli and Daman and Diu' },
    { id: 32, name: 'Lakshadweep' },
    { id: 33, name: 'Delhi' },
    { id: 34, name: 'Puducherry' },
    { id: 35, name: 'Ladakh' },
    { id: 36, name: 'Jammu and Kashmir' },
];

export const axelList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const perAxelTyres = [2, 4];
export const axelTypes = ['FRONT', 'HORSE', 'REAR'];
