/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
'use client';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import { IRootState } from '@/store';
import { toggleSidebar, toggleTheme } from '@/store/themeConfigSlice';
import Dropdown from '@/components/dropdown';
import IconSun from '@/components/icon/icon-sun';
import IconMoon from '@/components/icon/icon-moon';
import IconLaptop from '@/components/icon/icon-laptop';
import IconLogout from '@/components/icon/icon-logout';
import Image from 'next/image';
import { useClerk, useUser } from '@clerk/nextjs';
import { useSessionStore } from '@/state/clerkssn';
import { usePathname, useRouter } from 'next/navigation';
import { getCompanyByUser } from '@/lib/actions/user.action';
import { showToast } from '@/constants/sweetalerts';
import IconMenu from '../icon/icon-menu';
import AlertsWithIcon from '../fleetnet/alerts';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { COMPANY_LOGO_BLANK } from '@/constants/general';
import { CompanyListParams } from '@/lib/actions/shared.types';
import { showAlert } from '@/constants/messagebox';
import { useAppStore } from '@/state/company';
import { group } from 'console';

interface HeaderProps {
    clerkId: string | null;
    superAdmin: boolean | undefined;
    admin: boolean | undefined;
    testUser: boolean | undefined;
    isCookieExists: boolean;
    onValueChange: (value: boolean) => void;
    onProcessing: (value: boolean) => void;
    onCompanyPopulate: (value: number) => void;
}

const NavbarPlant = ({ clerkId, superAdmin, admin, testUser, isCookieExists, onValueChange, onProcessing, onCompanyPopulate }: HeaderProps) => {
    const pathname = usePathname();
    const router = useRouter();

    const dispatch = useDispatch();

    const user = useUser();
    const { signOut } = useClerk();

    const [company, setCompany] = useState<CompanyListParams[]>([]);
    const [isPopulated, setisPopulated] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [isAlertDisplayed, setisAlertDisplayed] = useState(false);

    // const [isSignOutClicked, setisSignOutClicked] = useState(false);

    const { clearSession } = useSessionStore();
    const { setSession: setSessionAppStore, clearSession: clearSessionAppStore } = useAppStore();

    const selectedCompany = async (selectedCompanyId: string, selectedCompanyName: string, selectedCompanyLogo: string, type: boolean, groupId: string) => {
        try {
            onProcessing(true);
            // console.log('Company selected', selectedCompanyId);
            const payload = {
                companyId: selectedCompanyId,
                companyName: selectedCompanyName,
                companyLogo: selectedCompanyLogo,
                groupId: groupId,
                type,
            };
            // console.log('before', payload);

            await fetch(`/api/company`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
            // console.log('after', ret);

            await setTimeout(async () => {
                if (selectedCompanyId !== '') {
                    const newSession = {
                        companyId: selectedCompanyId,
                        companyName: selectedCompanyName,
                        companyLogo: selectedCompanyLogo,
                        groupId: groupId,
                        isSuperAdmin: superAdmin,
                        isAdmin: admin,
                        testUser: testUser,
                        auth: [],
                        forms: [],
                    };

                    await setSessionAppStore(newSession);
                    // console.log('Selected Plant Selection Called', newSession);

                    if (pathname === '/confidential/authorization') {
                        // console.log('Confidential path found, Redirecting to /');
                        // This is done as there is no other option found inlayout form if confidential page is already opened in case of super admin then he selects a company
                        router.push('/');
                    }
                }

                onValueChange(!type);
            }, 1000);
            // Redirect to main dashboard page
        } catch (e) {
            // const error = e as AxiosError;
            console.log(e);
        }
    };

    useEffect(() => {
        const selector = document.querySelector('ul.horizontal-menu a[href="' + window.location.pathname + '"]');
        if (selector) {
            const all: any = document.querySelectorAll('ul.horizontal-menu .nav-link.active');
            for (let i = 0; i < all.length; i++) {
                all[0]?.classList.remove('active');
            }

            const allLinks = document.querySelectorAll('ul.horizontal-menu a.active');
            for (let i = 0; i < allLinks.length; i++) {
                const element = allLinks[i];
                element?.classList.remove('active');
            }
            selector?.classList.add('active');

            const ul: any = selector.closest('ul.sub-menu');
            if (ul) {
                let ele: any = ul.closest('li.menu').querySelectorAll('.nav-link');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele?.classList.add('active');
                    });
                }
            }
        }
    }, [pathname]);

    useEffect(() => {
        if (clerkId) {
            // console.log('ClerkId:', clerkId);
            // setIsLoader(true);
            const updateCompany = async () => {
                setIsLoader(true);

                const ret: any = await getCompanyByUser({ clerkId });
                const { comp, isActive } = ret;

                if (!isActive) {
                    setIsLoader(false);
                    await showAlert({ title: 'You are currently restricted from using FleetNet!', message: 'Please contact your Administrator.', type: 'error', timerDuration: 0 });
                    await Logout();
                } else {
                    setCompany(comp);
                    onCompanyPopulate(comp.length);

                    if (isPopulated === false) {
                        setisPopulated(true);
                        // console.log('Called here1', company.length, themeConfig.sidebar, window.innerWidth);

                        if (company.length === 0 && superAdmin !== undefined && superAdmin === false && !isAlertDisplayed) {
                            await setisAlertDisplayed(true);
                        }
                    }
                }
            };

            updateCompany();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clerkId]);

    useEffect(() => {
        if (isAlertDisplayed) {
            !themeConfig.sidebar && dispatch(toggleSidebar());

            // showAlert('Currently no company is assigned to you!', 'Please contact your Administrator.', 'error', 10000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAlertDisplayed]);

    useEffect(() => {
        if (superAdmin !== undefined && superAdmin === false && !isCookieExists) {
            setIsLoader(false);
        } else if (superAdmin === true && !isCookieExists) {
            setIsLoader(false);
        }
    }, [superAdmin, isCookieExists]);

    const Logout = async () => {
        setIsLoader(true);
        await onProcessing(true);
        // setisSignOutClicked(true);
        await selectedCompany('', '', '', false, '');
        await signOut(async () => {
            // console.log(pathname);
            if (pathname !== '/') {
                router.push('/');
            }
            await clearSession();
            await clearSessionAppStore();
            await onProcessing(false);
            showToast({ title: 'Signed out successfully.', position: 'bottom-right', type: 'success' });
        });
    };

    const companyTemplate = (props: any) => {
        // console.log(props);
        const imageUrl = props.picture || COMPANY_LOGO_BLANK;

        return (
            <div className="inline-flex" style={{ width: 'auto' }}>
                <Image className="mt-2 size-9 max-w-none rounded-full" src={imageUrl} alt="Company_Logo" style={{ width: '40px', height: '40px', borderRadius: '50%' }} width={100} height={100} />
                <div className="">
                    <div className="font-semibold">{props.name}</div>
                    <div className="mb-1 text-sm font-normal italic">{props.address}</div>
                </div>
            </div>
        );
    };

    const valueTemplate = (props: any) => {
        // console.log(props);
        const imageUrl = props.picture || COMPANY_LOGO_BLANK;

        return (
            <div className="inline-flex justify-start gap-2 align-middle" style={{ width: '200px', height: '100%' }}>
                <Image className="ml-1 mt-[0.5] size-5 max-w-none rounded-full" src={imageUrl} height={100} width={100} alt="company" style={{ width: '28px', height: '28px', borderRadius: '50%' }} />
                <div className="mt-1 font-semibold"> {props.name} </div>
            </div>
        );
    };

    const isRtl = useSelector((state: IRootState) => state.themeConfig.rtlClass) === 'rtl';

    const themeConfig = useSelector((state: IRootState) => state.themeConfig);

    return (
        <header className={`z-40 ${themeConfig.semidark && themeConfig.menu === 'horizontal' ? 'dark' : ''}`}>
            <div className="shadow-sm">
                <div className="relative flex w-full items-center bg-white px-5 py-2.5 dark:bg-black">
                    <div className="horizontal-logo flex items-center justify-between lg:hidden ltr:mr-2 rtl:ml-2">
                        <Link href="/" className="main-logo flex shrink-0 items-center">
                            <Image className="inline w-8 ltr:-ml-1 rtl:-mr-1" src="/assets/images/logo.svg" alt="logo" width={80} height={20} />
                            <span className="hidden align-middle text-2xl  font-semibold  transition-all duration-300 dark:text-white-light md:inline ltr:ml-1.5 rtl:mr-1.5">FleetNet</span>
                        </Link>
                        <button
                            type="button"
                            className="collapse-icon flex flex-none rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:text-[#d0d2d6] dark:hover:bg-dark/60 dark:hover:text-primary lg:hidden ltr:ml-2 rtl:mr-2"
                            onClick={() => dispatch(toggleSidebar())}
                        >
                            <IconMenu className="size-5" />
                        </button>
                    </div>

                    <div className="flex items-center space-x-1.5 dark:text-[#d0d2d6] lg:space-x-2 ltr:ml-auto rtl:mr-auto rtl:space-x-reverse">
                        <div className="inline-flex gap-2">
                            <span
                                className={`m-auto inline-block size-6 animate-spin rounded-full border-[3px] border-success border-l-transparent align-middle ${isLoader ? 'flex' : 'hidden'}`}
                            ></span>
                            <div className={`items-center  ${superAdmin && !isCookieExists ? 'flex' : 'hidden'}`} style={{ height: '100%' }}>
                                <DropDownListComponent
                                    id="comboelement"
                                    dataSource={company?.map((item: any) => {
                                        return { id: item.id, name: item.name, address: item.address, picture: item.picture, groupId: item.groupId };
                                    })}
                                    placeholder="Choose a Company"
                                    fields={{ value: 'id', text: 'name' }}
                                    itemTemplate={companyTemplate}
                                    valueTemplate={valueTemplate}
                                    popupWidth={'350px'}
                                    change={(e) => {
                                        // console.log(e.itemData);
                                        if (e.itemData) {
                                            setIsLoader(true);
                                            selectedCompany(e.itemData.id, e.itemData.name, e.itemData.picture, true, e.itemData.groupId);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className={`items-center ${superAdmin !== undefined && superAdmin === false && !isCookieExists ? 'flex' : 'hidden'}`}>
                            <AlertsWithIcon msg={'Currently no company is assigned to you!'} msg2={'Please contact your Administrator.'} />
                        </div>
                        <div>
                            {themeConfig.theme === 'light' ? (
                                <button
                                    className={`${
                                        themeConfig.theme === 'light' &&
                                        'flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60'
                                    }`}
                                    onClick={() => dispatch(toggleTheme('dark'))}
                                >
                                    <IconSun />
                                </button>
                            ) : (
                                ''
                            )}
                            {themeConfig.theme === 'dark' && (
                                <button
                                    className={`${
                                        themeConfig.theme === 'dark' &&
                                        'flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60'
                                    }`}
                                    onClick={() => dispatch(toggleTheme('system'))}
                                >
                                    <IconMoon />
                                </button>
                            )}
                            {themeConfig.theme === 'system' && (
                                <button
                                    className={`${
                                        themeConfig.theme === 'system' &&
                                        'flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60'
                                    }`}
                                    onClick={() => dispatch(toggleTheme('light'))}
                                >
                                    <IconLaptop />
                                </button>
                            )}
                        </div>
                        <div style={{ height: '37px' }}></div>
                        <div className="dropdown flex">
                            <Dropdown
                                offset={[0, 8]}
                                placement={`${isRtl ? 'bottom-start' : 'bottom-end'}`}
                                btnClassName="relative group block"
                                button={
                                    <Image
                                        className="size-9 rounded-full object-cover saturate-50 group-hover:saturate-100"
                                        src={user.user?.imageUrl || '/assets/images/Man_Avatar.png'}
                                        alt="userProfile"
                                        width={48}
                                        height={48}
                                    />
                                }
                            >
                                <ul className="w-[330px] !py-0 font-semibold text-dark dark:text-white-dark dark:hover:text-white-light/90">
                                    <li>
                                        <div className="flex items-center p-4">
                                            <Image className="size-10 rounded-md object-cover" src={user.user?.imageUrl || '/assets/images/Man_Avatar.png'} alt="userProfile" width={48} height={48} />
                                            <div className="truncate ltr:pl-4 rtl:pr-4">
                                                <h4 className="text-base" style={{ display: 'flex', alignItems: 'center' }}>
                                                    {user.user?.fullName}
                                                    {superAdmin === true ? (
                                                        <span className="rounded bg-danger px-1 text-xs text-white ltr:ml-2 rtl:ml-2">Super Admin</span>
                                                    ) : admin === true ? (
                                                        <span className="rounded bg-success-light px-1 text-xs text-success ltr:ml-2 rtl:ml-2">Admin</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </h4>
                                                <button type="button" className="text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white">
                                                    {user.user?.emailAddresses[0].emailAddress}
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                    {/* <li>
                                        <Link href="/users/clerk_profile" className="dark:hover:text-white">
                                            <FontAwesomeIcon icon={faUserCircle} className="size-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                                            Profile (Clerk)
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href="/users/profile" className="dark:hover:text-white">
                                            <IconUser className="size-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                                            Profile
                                        </Link>
                                    </li> */}
                                    {/* <li>
                                        <Link href="/auth/boxed-lockscreen" className="dark:hover:text-white">
                                            <IconLockDots className="size-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                                            Lock Screen
                                        </Link>
                                    </li> */}
                                    <li className="border-t border-white-light dark:border-white-light/10">
                                        <Link href={'/'} onClick={Logout} className="!py-3 text-danger">
                                            <IconLogout className="size-4.5 shrink-0 rotate-90 ltr:mr-2 rtl:ml-2" />
                                            Sign Out
                                        </Link>
                                    </li>
                                </ul>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default NavbarPlant;
