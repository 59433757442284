import { FC } from 'react';

interface IconMenuProps {
    className?: string;
}

const IconMenuTrolleyAssignment: FC<IconMenuProps> = ({ className }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 616 596" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M623.6 197.7L570.3 144.4C559.8 133.9 545.6 128 530.8 128H464c-17.67 0-32 14.33-32 32v176H256v.3613C242.6 326.2 226.1 320 208 320c-26.29 0-49.42 12.85-64 32.44C129.4 332.9 106.3 320 80 320C35.82 320 0 355.8 0 400C0 444.2 35.82 480 80 480v-.6133c26.23-.0527 49.45-12.27 64-31.81c18.41 24.72 50.43 38.7 85.25 29.65c25.17-6.539 46.21-26.12 54.44-50.78c4.941-14.8 5.423-29.18 2.736-42.4h163.2c-2.691 13.25-2.176 27.61 2.792 42.45c8.406 25.09 29.95 44.92 55.67 51.11c52.79 12.71 99.92-26.95 99.92-77.6c0-5.48-.5475-10.83-1.598-15.1L608 384c17.67 0 32-14.33 32-32V237.3C640 222.4 634.1 208.2 623.6 197.7zM80 431.9c-17.64 0-32-14.33-32-31.97s14.36-32.02 32-32.02s32 14.35 32 31.1S97.64 431.9 80 431.9zM208 431.9c-17.64 0-32-14.33-32-31.97s14.36-32.02 32-32.02s32 14.35 32 31.1S225.6 431.9 208 431.9zM480 176h50.75c2.109 0 4.156 .8594 5.656 2.344L582.1 224H480V176zM528 431.9c-17.64 0-32-14.35-32-31.99s14.36-31.1 32-31.1s32 14.37 32 32.02S545.6 431.9 528 431.9zM592 336H576v.3613C562.6 326.2 546.1 320 528 320c-18.09 0-34.6 6.232-48 16.36V272h112V336zM24 304c13.25 0 24-10.75 24-24v-192c0-4.406 3.594-8 8-8h288C348.4 80 352 83.59 352 88v192c0 13.25 10.75 24 24 24s24-10.75 24-24v-192C400 57.13 374.9 32 344 32h-288C25.12 32 0 57.13 0 88v192C0 293.3 10.75 304 24 304zM176 128v128c0 13.25 10.75 24 24 24S224 269.3 224 256V128c0-13.25-10.75-24-24-24S176 114.8 176 128zM264 128v128c0 13.25 10.75 24 24 24S312 269.3 312 256V128c0-13.25-10.75-24-24-24S264 114.8 264 128zM88 128v128c0 13.25 10.75 24 24 24S136 269.3 136 256V128c0-13.25-10.75-24-24-24S88 114.8 88 128z"
                fill="currentColor"
                strokeWidth="1.5"
            />
        </svg>
    );
};

export default IconMenuTrolleyAssignment;
