import { FC } from 'react';

interface IconMenuProps {
    className?: string;
}

const IconMenuTruck: FC<IconMenuProps> = ({ className }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 616 596" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M48 287.9H352c26.51 0 48-21.48 48-47.98V79.99C400 53.49 378.5 32 352 32H48C21.49 32 0 53.49 0 79.99l0 159.9C0 266.4 21.49 287.9 48 287.9zM304 79.98h32v159.9h-32V79.98zM224 79.98h32v159.9H224V79.98zM144 79.98h32v159.9h-32V79.98zM64 79.98h32v159.9H64V79.98zM621.3 237.2l-58.5-58.49c-12-11.1-28.25-18.62-45.25-18.62H464c-17.75 0-32 14.25-32 31.1v127.9L32 320c-17.75 0-32 14.25-32 31.99v43.75c0 40.86 28.75 78.1 69.13 83.47C99.75 483.3 127.4 469.7 144 447.6c18.38 24.62 50.38 38.65 85.25 29.65c25.25-6.498 46.06-26.16 54.44-50.78c4.875-14.87 5.361-29.28 2.736-42.4h163.2c-2.75 13.12-2.208 27.57 2.792 42.45c8.375 25.12 29.92 44.86 55.67 51.11c52.75 12.75 99.92-26.99 99.92-77.6c0-5.498-.5983-10.87-1.598-15.1L624 384.1c8.75 0 16-7.249 16-15.1V282.6C640 265.5 633.3 249.2 621.3 237.2zM80 431.9c-17.62 0-32-14.36-32-31.97c0-17.62 14.38-32.02 32-32.02s32 14.38 32 31.1C112 417.6 97.63 431.9 80 431.9zM208 431.9c-17.62 0-32-14.36-32-31.97c0-17.62 14.38-32.02 32-32.02s32 14.38 32 31.1C240 417.6 225.6 431.9 208 431.9zM528 431.9c-17.62 0-32-14.38-32-31.99c0-17.62 14.38-31.1 32-31.1s32 14.4 32 32.02C560 417.6 545.6 431.9 528 431.9zM480 255.1V207.1h37.5c4.25 0 8.25 1.751 11.25 4.749l43.38 43.24L480 255.1z"
                fill="currentColor"
                strokeWidth="1.5"
            />
        </svg>
    );
};

export default IconMenuTruck;
