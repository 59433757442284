import { FC } from 'react';

interface IconMenuProps {
    className?: string;
}

const IconMenuTyreSetup: FC<IconMenuProps> = ({ className }) => {
    return (
        <svg width="18" height="20" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M480 192h-9.375c-4.5-14.88-10.25-29.12-17.5-42.5l6.5-6.625c12.5-12.5 12.5-32.75 0-45.25l-45.25-45.25c-12.5-12.5-32.75-12.5-45.25 0l-6.625 6.5C349.1 51.63 334.9 45.88 320 41.38V32c0-17.62-14.38-32-32-32H224C206.4 0 192 14.38 192 32v9.375c-14.88 4.5-29.12 10.25-42.5 17.5l-6.625-6.5c-12.5-12.5-32.75-12.5-45.25 0L52.38 97.63c-12.5 12.5-12.5 32.75 0 45.25l6.5 6.625C51.63 162.9 45.88 177.1 41.38 192H32C14.38 192 0 206.4 0 224v64c0 17.62 14.38 32 32 32h9.375c4.5 14.88 10.25 29.12 17.5 42.63l-6.5 6.5c-12.5 12.5-12.5 32.75 0 45.25l45.25 45.25c12.5 12.5 32.75 12.5 45.25 0l6.625-6.5C162.9 460.4 177.1 466.1 192 470.6V480c0 17.62 14.38 32 32 32h64c17.62 0 32-14.38 32-32v-9.375c14.88-4.5 29.12-10.25 42.5-17.5l6.625 6.5c12.5 12.5 32.75 12.5 45.25 0l45.25-45.25c12.5-12.5 12.5-32.75 0-45.25l-6.5-6.5c7.25-13.5 13-27.75 17.5-42.63H480c17.62 0 32-14.38 32-32V224C512 206.4 497.6 192 480 192zM256 384c-70.75 0-128-57.38-128-128c0-70.75 57.25-128 128-128s128 57.25 128 128C384 326.6 326.8 384 256 384zM256 160C242.7 160 232 170.7 232 184C232 197.3 242.7 208 256 208s24-10.75 24-24C280 170.7 269.3 160 256 160zM210.3 241.2C214.4 228.6 207.5 215 194.9 210.9C182.3 206.8 168.8 213.7 164.7 226.3S167.5 252.5 180.1 256.6S206.3 253.8 210.3 241.2zM194.3 300.1c-7.791 10.72-5.414 25.73 5.309 33.52c10.72 7.791 25.73 5.412 33.52-5.311s5.414-25.73-5.308-33.53C217.1 287 202.1 289.4 194.3 300.1zM284.2 294.8c-10.72 7.791-13.1 22.8-5.304 33.53s22.8 13.1 33.52 5.311c10.72-7.791 13.1-22.8 5.309-33.52C309.9 289.4 294.9 287 284.2 294.8zM317.1 210.9c-12.61 4.096-19.51 17.64-15.41 30.24c4.096 12.61 17.64 19.5 30.25 15.41s19.5-17.64 15.41-30.24S329.7 206.8 317.1 210.9z"
                fill="currentColor"
                strokeWidth="1.5"
            />
        </svg>
    );
};

export default IconMenuTyreSetup;
